/* eslint-disable no-prototype-builtins */
/* eslint-disable no-restricted-syntax */
/* eslint-disable prefer-const */
import React from 'react';
import { useArchiveData } from '../../hooks/getArchiveData';

const ArchivesList = () => {
    const blogPostsData = useArchiveData();
    const blogPosts = blogPostsData.allWordpressPost.nodes;

    const monthNames = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
    ];

    const monthArchive = {
        2024: {
            0: [],
            1: [],
            2: [],
            3: [],
            4: [],
            5: [],
            6: [],
            7: [],
            8: [],
            9: [],
            10: [],
            11: [],
        },
        2023: {
            0: [],
            1: [],
            2: [],
            3: [],
            4: [],
            5: [],
            6: [],
            7: [],
            8: [],
            9: [],
            10: [],
            11: [],
        },
        2022: {
            0: [],
            1: [],
            2: [],
            3: [],
            4: [],
            5: [],
            6: [],
            7: [],
            8: [],
            9: [],
            10: [],
            11: [],
        },
        2021: {
        0: [],
        1: [],
        2: [],
        3: [],
        4: [],
        5: [],
        6: [],
        7: [],
        8: [],
        9: [],
        10: [],
        11: [],
    },
		2020: {
            0: [],
            1: [],
            2: [],
            3: [],
            4: [],
            5: [],
            6: [],
            7: [],
            8: [],
            9: [],
            10: [],
            11: [],
        },

        2019: {
            0: [],
            1: [],
            2: [],
            3: [],
            4: [],
            5: [],
            6: [],
            7: [],
            8: [],
            9: [],
            10: [],
            11: [],
        },
        2018: {
            0: [],
            1: [],
            2: [],
            3: [],
            4: [],
            5: [],
            6: [],
            7: [],
            8: [],
            9: [],
            10: [],
            11: [],
        },
        2017: {
            0: [],
            1: [],
            2: [],
            3: [],
            4: [],
            5: [],
            6: [],
            7: [],
            8: [],
            9: [],
            10: [],
            11: [],
        },
        2016: {
            0: [],
            1: [],
            2: [],
            3: [],
            4: [],
            5: [],
            6: [],
            7: [],
            8: [],
            9: [],
            10: [],
            11: [],
        },
        2015: {
            0: [],
            1: [],
            2: [],
            3: [],
            4: [],
            5: [],
            6: [],
            7: [],
            8: [],
            9: [],
            10: [],
            11: [],
        },
        2014: {
            0: [],
            1: [],
            2: [],
            3: [],
            4: [],
            5: [],
            6: [],
            7: [],
            8: [],
            9: [],
            10: [],
            11: [],
        },
        2013: {
            0: [],
            1: [],
            2: [],
            3: [],
            4: [],
            5: [],
            6: [],
            7: [],
            8: [],
            9: [],
            10: [],
            11: [],
        },
        2012: {
            0: [],
            1: [],
            2: [],
            3: [],
            4: [],
            5: [],
            6: [],
            7: [],
            8: [],
            9: [],
            10: [],
            11: [],
        },
        2011: {
            0: [],
            1: [],
            2: [],
            3: [],
            4: [],
            5: [],
            6: [],
            7: [],
            8: [],
            9: [],
            10: [],
            11: [],
        },
    };

    blogPosts.forEach(post => {
        const postMonth = new Date(post.date).getMonth();
        const postYear = new Date(post.date).getFullYear();
        const postId = post.wordpress_id;
        monthArchive[postYear][postMonth].push([postId]);
    });
    // console.log(monthArchive);

    let returnData = '';
    // -- for each year
    Object.keys(monthArchive)
        .reverse()
        .forEach(year => {
            const yearName = year;
            // console.log(yearName);
            Object.keys(monthArchive[year])
                .reverse()
                .forEach(month => {
                    const monthName = monthNames[month];
                    const postNumber = monthArchive[year][month].length;
                    if (postNumber > 0) {
                        // console.log(monthName);
                        // console.log(postNumber);
                        returnData += `<h5><a href="/archive/${monthName}-${yearName}">${monthName} (${yearName}) <span>${postNumber}</span></a></h5>`;
                    }
                });
        });

    return (
        <div
            dangerouslySetInnerHTML={{
                __html: returnData,
            }}
        />
    );
};

const Archives = () => (
    <div className="sidewidget">
        <h3>ARCHIVES</h3>
        <ArchivesList />
    </div>
);

export default Archives;
